<template>
  <section>
    <NativeCampaignManagementFilter />
    <Preloader v-if="callingAPI" on-top />
    <Autoloader
      v-if="$store.getters['nativeCampaignManagement/autoRefresh']"
      :callback="() => $store.dispatch('nativeCampaignManagement/fetch')"
      :height="2"
      :interval="60"
    />
    <NativeCampaignManagementTable />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Store from '@/store'
import Preloader from '@/components/preloader/Preloader'
import NativeCampaignManagementFilter from '@/components/filter/FilterNativeCampaignManagement'
import NativeCampaignManagementTable from '@/components/table/NativeCampaignManagementTable'
import Autoloader from '@/components/Autoloader'

export default {
  name: 'NativeCampaignManagementListView',
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    NativeCampaignManagementTable,
    NativeCampaignManagementFilter,
    Preloader,
    Autoloader
  },
  beforeRouteEnter (to, from, next) {
    if (!from.path.startsWith('/nativeCampaignManagement/')) {
      Store.commit('nativeCampaignManagement/setCurrentPage', 1)
      Store.commit('nativeCampaignManagement/resetFilter')
    }
    next()
  }
}
</script>
